<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-base py-7 mb--100">
      <div class="container">
        <notifications></notifications>
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="text-center mb-4" style="margin-bottom: 0px">
              <h1 class="text-white">
                Get started immediately!
              </h1>
              <p class="text-lead text-white">
                As humans we tend to forget things time-to-time.
                <br> All fine, we got your back, just request a new login Password
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>Reset password</small>
              </div>

              <form class="needs-validation" @submit.prevent="handleSubmit()">
                <base-input
                  class="mb-3"
                  name="Email"
                  prepend-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="form.data.attributes.email"
                  required
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.email" />

                <div class="text-center">
                  <!-- <base-button type="submit" @click.prevent="handleSubmit" class="my-4">Sign in</base-button> -->
                  <base-button type="primary" native-type="submit" class="my-4"
                    >Send Password Reset Link</base-button
                  >
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6"></div>
            <div class="col-6  text-right">
              <a href="/login" class="text-light"
                ><small>
                  <i class="fa fa-arrow-left"/> Back to login
                </small>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError },
  data() {
    return {
      form: {
        data: {
          type: "password-forgot",
          attributes: {
            email: "",
            redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
          },
        },
      },
    };
  },
  methods: {
    async handleSubmit() {
      if (process.env.VUE_APP_IS_DEMO == 1) {
        await this.$notify({
          type: "danger",
          message: "Password reset is disabled in the demo.",
        });
        return;
      }
      try {
        await this.$store.dispatch("reset/forgotPassword", this.form.data);
        await this.$notify({
          type: "success",
          message: "An email with reset password link was sent.",
        });
      } catch (error) {
        await this.$notify({
          type: "danger",
          message: "We can't find a user with that e-mail address.",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
